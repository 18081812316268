import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';

export default class CategoryForm extends React.Component {

  constructor (props) {
    super (props);
    if (props.object) {
      this.state.formData = props.object;
    } else {
      this.state = {
        formData: this.props.blank
      }
    }
    this.state.validate = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange (event) {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    this.setState({formData: {...this.state.formData, [fieldName]: fleldVal}});
  }

  handleAddItem () {
    let items = [...this.state.formData.items];
    items.push(this.state.formData.item);
    this.setState({
      formData: {
        ...this.state.formData,
        items: items,
        item: '',
      }
    });
  }

  handleRemove (index) {
    let items = [...this.state.formData.items];
    items.splice(index, 1);
    this.setState({
      formData: {
        ...this.state.formData,
        items: items
      }
    });
  }

  handleSubmit (event) {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      validated: true
    });
    if (form.checkValidity() === false) {
      return;
    }
    this.props.handleSave(this.state.formData);
  }

  componentWillReceiveProps (props) {
    if (props.currentObj) {
      this.setState({
        formData: {
          ...props.currentObj,
          password: '',
          confirmPassword: ''
        },
        validated: false
      });
    }
  }

  render () {
    return (
      <div>
        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Username</Form.Label>
              <Form.Control size="lg" type="text" placeholder="Username" required value={this.state.formData.username || ''} name="username" onChange={this.handleChange} />
              <Form.Control.Feedback type="invalid">
                Please input a username
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Role</Form.Label>
              <Form.Control size="lg" as="select" name="role" value={this.state.formData.role || ''} onChange={this.handleChange} >
                <option key=""></option>
                <option key="privileged" value="privileged">Privileged User</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Password</Form.Label>
              <Form.Control
                size="lg"
                type="password"
                placeholder="Password"
                required={!this.state.formData._id}
                value={this.state.formData.password || ''}
                name="password"
                onChange={this.handleChange} />
              <Form.Control.Feedback type="invalid">
                Input a password
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                size="lg"
                type="password"
                placeholder="Confirm Password"
                value={this.state.formData.confirmPassword || ''}
                name="confirmPassword"
                required={this.state.formData.password !== ''}
                pattern={this.state.formData.password}
                onChange={this.handleChange} />
              <Form.Control.Feedback type="invalid">
                Passwords must match.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="First Last"
                required
                value={this.state.formData.name || ''}
                name="name"
                onChange={this.handleChange} />
                <Form.Control.Feedback type="invalid">
                Please input the user's name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>User Email</Form.Label>
              <Form.Control
                size="lg"
                type="email"
                placeholder="example@example.com"
                value={this.state.formData.email || ''}
                name="email"
                required
                onChange={this.handleChange} />
              <Form.Control.Feedback type="invalid">
                Please input the user's email address.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Button type="submit">Save</Button>
        </Form>
      </div>
    )
  }
}