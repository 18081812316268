import React from 'react';
import DataList from './dataList';
import Toast from 'react-bootstrap/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Management extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      data: [],
      show: false,
      toastIcon: ['fal', 'check-circle'],
    }
  }

  async refresh () {
    let data = (await this.props.api.get(this.props.extra.apiUrl)).data;
    this.setState({
      data: data
    });
  }

  async handleSave (obj) {
    this.setState({
      currentObj: obj
    });
    if (obj._id) {
      await this.props.api.put(`${this.props.extra.apiUrl}/${obj._id}`, obj);
    } else {
      await this.props.api.post(this.props.extra.apiUrl, obj);
    }
    this.setState({
      show: true,
      toastIcon: ['fal', 'check-circle'],
      toastText: 'Save successful!'
    });
    this.refresh();
  }

  handleNew () {
    this.setState({
      currentObj: {...this.props.extra.blank}
    });
  }

  handleSelect (obj) {
    this.setState({
      currentObj: obj
    });
  }

  handleHide () {
    this.setState({
      show: false,
      toastText: '',
    });
  }

  async componentWillReceiveProps (props) {
    let data = (await props.api.get(props.extra.apiUrl)).data;
    this.setState({
      data: data,
      currentObj: props.extra.blank
    });
  }

  componentDidMount () {
    this.refresh();
  }

  render () {
    return (
      <div className="management-flex" style={{position: 'relative'}}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          <Toast show={this.state.show} onClose={this.handleHide.bind(this)} delay={3000} autohide>
            <Toast.Header>
              <FontAwesomeIcon
                style={{color: 'var(--green)', paddingRight: '5px', width: '1.5em'}}
                icon={this.state.toastIcon} /> <strong>{this.state.toastText}</strong>
            </Toast.Header>
          </Toast>
        </div>
        <DataList
          data={this.state.data}
          handleClick={this.handleSelect.bind(this)}
          titleProp={this.props.extra.titleProp}
          descriptionProp={this.props.extra.descriptionProp}
          handleNew={this.handleNew.bind(this)} />
        <div className="grower">
          <this.props.extra.formComponent handleSave={this.handleSave.bind(this)} currentObj={this.state.currentObj} blank={this.props.extra.blank} />
        </div>
      </div>
    )
    
  }
}