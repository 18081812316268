import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default class Topbar extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      show: false
    }
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleAuth = this.handleAuth.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }
  
  handleClose () {
    this.setState({
      show: false,
    });
  }

  handleShow () {
    this.setState({
      show: true,
    });
  }

  handleLogout () {
    this.props.api.logout()
      .then(() => {
        this.props.handleLogout();
      });
  }

  handleChange(event) {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    this.setState({[fieldName]: fleldVal});
  }

  async handleAuth () {
    this.props.api.login({
      username: this.state.username,
      password: this.state.password
    })
    .then(data => {
      this.props.handleAuthentication(data);
      this.handleClose();
    })
    .catch(err => {
      if (err.response) {
        this.setState({
          error: err.response.data.message
        })
      } else {
        this.setState({
          error: 'Something went wrong on the server'
        })
      }
    });
  }

  render () {
    return (
      <div>
        <Navbar bg="light" variant="light">
          <LinkContainer to="/"><Navbar.Brand href="#home">
            <img
              src="/logo.png"
              width="300"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand></LinkContainer>
          <Nav className="mr-auto">
            <LinkContainer to="/"><Nav.Link>Home</Nav.Link></LinkContainer>
            <LinkContainer to="/capability"><Nav.Link>Capabilities</Nav.Link></LinkContainer>
            <LinkContainer to="/find-suppliers"><Nav.Link>Find A Supplier</Nav.Link></LinkContainer>
            {
              this.props.loggedInStatus === 'LOGGED_IN' &&
              <React.Fragment>
                { this.props.checkPermissions('UPDATE_CAPABILITIES') && <LinkContainer to="/approve-submissions"><Nav.Link>Approve submissions</Nav.Link></LinkContainer> }
                { this.props.checkPermissions('UPDATE_CATEGORIES') && <LinkContainer to="/lookup-management"><Nav.Link>Lookup Management</Nav.Link></LinkContainer> }
                { this.props.checkPermissions('UPDATE_USERS') && <LinkContainer to="/user-management"><Nav.Link>User Management</Nav.Link></LinkContainer> }
                { this.props.checkPermissions('LOAD_CAPABILITIES') && <LinkContainer to="/data-loader"><Nav.Link>Data Loader</Nav.Link></LinkContainer> }
              </React.Fragment>
            }
          </Nav>
          <Form inline >
            {
              this.props.loggedInStatus === 'LOGGED_IN' ?
                <Button variant="outline-primary" onClick={this.handleLogout}>Logout</Button> :
                <Button variant="outline-primary" onClick={this.handleShow}>Admin Login</Button>
            }
          </Form>
        </Navbar>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Log In</Modal.Title>
          </Modal.Header>
          <Modal.Body className="login-body">
            <Alert variant='danger' className={this.state.error ? '' : 'hidden'}>
              {this.state.error}
            </Alert>
            <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon="user" /></InputGroup.Text>
            </InputGroup.Prepend>
              <FormControl placeholder="Username" name="username" onChange={this.handleChange} />
            </InputGroup>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon="key" /></InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl placeholder="Password" type="password" name="password" onChange={this.handleChange} />
            </InputGroup>
            <Button variant="primary" onClick={this.handleAuth}>Log In</Button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}