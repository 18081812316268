import { Route, Redirect } from 'react-router-dom';
import React from 'react';

export default function PrivateRoute ({component: Component, loggedInStatus, api, formatPhone, checkPermission, permission, extraProps, ...rest}) {
  return (
    <Route {...rest} render={(props) => (
      loggedInStatus === 'LOGGED_IN' && checkPermission(permission)
        ? <Component {...props} api={api} formatPhone={formatPhone} extra={extraProps} />
        : loggedInStatus === 'NOT_LOGGED_IN' ? <Redirect to='/' />
        : loggedInStatus === null ? null : !checkPermission(permission) ? <Redirect to='/' /> : null
    )} />
  )
}