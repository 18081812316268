import React from 'react';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import FormControl from 'react-bootstrap/FormControl';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import InputGroup from 'react-bootstrap/InputGroup';
import CompanyForm from './company.form';
import Form from 'react-bootstrap/Form';
import states from '../state-list';
import CategoryList from './category.list';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class CapabilityTable extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      data: props.data || [],
      categories: [],
      query: '',
      orFilters: [],
      andFilters: {},
      neFilters: [],
      currentComment: '',
      show: false
    }
    this.updateAndFilters = this.updateAndFilters.bind(this);
    this.handleExtra = this.handleExtra.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleApproval = this.handleApproval.bind(this);
    this.handleDenial = this.handleDenial.bind(this);
    this.handleChangeComment = this.handleChangeComment.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.search = this.search.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.isChecked = this.isChecked.bind(this);
  }

  formatPhone (tel) {
    if (!tel) { return ''; }

    var value = tel.toString().trim().replace(/^\+/, '');

    if (value.match(/[^0-9]/)) {
      return tel;
    }

    var country, city, number;

    switch (value.length) {
    case 10: // +1PPP####### -> C (PPP) ###-####
      country = 1;
      city = value.slice(0, 3);
      number = value.slice(3);
      break;

    case 11: // +CPPP####### -> CCC (PP) ###-####
      country = value[0];
      city = value.slice(1, 4);
      number = value.slice(4);
      break;

    case 12: // +CCCPP####### -> CCC (PP) ###-####
      country = value.slice(0, 3);
      city = value.slice(3, 5);
      number = value.slice(5);
      break;

    default:
      return tel;
    }

    if (country === 1) {
      country = '';
    }

    number = number.slice(0, 3) + '-' + number.slice(3);

    return (country + ' (' + city + ') ' + number).trim();
  }

  isChecked (item) {
    return this.state.orFilters.includes(item);
  }

  clearSearch () {
    this.setState({
      orFilters: [],
      andFilters: {},
      neFilters: []
    }, () => {
      this.search();
    });
    
  }

  updateFilters (value, arrayName) {
    let filters = [...this.state[arrayName]];
    let index = filters.findIndex(filter => {
      return JSON.stringify(filter) === JSON.stringify(value);
    })
    if (index >= 0) {
      filters.splice(index, 1);
    } else {
      filters.push(value);
    }

    this.setState({
      [arrayName]: filters
    });
  }

  updateAndFilters (e) {
    let fieldName = e.target.name;
    let value = e.target.value;
    let andFilters = {...this.state.andFilters};
    andFilters[fieldName] = value
    this.setState({
      andFilters: andFilters
    });
  }

  handleAddressParts (formattedAddress, parts) {
    this.setState({
      currentCapability: {
        ...this.state.currentCapability,
        displayAddress: formattedAddress, addressParts: parts}
    });
  }

  handleApproval (datum) {
    datum.approved = true;
    this.props.api.put(`admin/capabilities/${datum._id}`, datum)
      .then(() => {
        this.handleRefresh();
      });
  }

  handleDenial (datum) {
    datum.approved = false;
    datum.denied = true;
    this.props.api.put(`admin/capabilities/${datum._id}`, datum)
      .then(() => {
        this.handleClose('showDenial');
        this.props.handleRefresh();
      });
  }

  handleEdit (event) {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    this.setState({currentCapability: {...this.state.currentCapability, [fieldName]: fleldVal}});
  }

  handleEditCapability (event, index) {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    let capability = {...this.state.currentCapability};
    capability.capabilities[index] = {...capability.capabilities[index], [fieldName]: fleldVal};
    this.setState({
      currentCapability: capability
    });
  }

  handleAddRow () {
    let currentCapability = {...this.state.currentCapability};
    currentCapability.capabilities.push({new: true});
    this.setState({
      currentCapability: currentCapability
    });
  }

  handleRemoveRow (i) {
    let currentCapability = {...this.state.currentCapability};
    currentCapability.capabilities.splice(i, 1);
    this.setState({
      currentCapability: currentCapability
    });
  }

  handleChangeComment (event) {
    var value = event.target.value;
    var capability = JSON.parse(JSON.stringify(this.state.currentCapability));
    capability.deniedComment = value;
    this.setState({
      currentCapability: capability
    })
  }

  handleSave (capability, approve) {
    if (approve) {
      capability.approved = true;
    }
    this.props.api.put(`admin/capabilities/${capability._id}`, capability)
      .then(() => {
        this.handleClose('showEdit');
        this.handleRefresh();
      });
  }

  displayCapabilities (items) {
    if (items) {
      return items.map(item => item.name).join(', ');
    }
  }

  handleShow (prop, datum, modal) {
    this.setState({
      [prop]: datum,
      [modal]: true
    })
  }

  handleClose (prop, modal) {
    this.setState({
      [modal]: false,
      [prop]: null
    })
  }

  handleExtra (arrayProp, i) {
    let data = [...this.state[arrayProp]];
    data[i].showExtra = !data[i].showExtra;
    this.setState({[arrayProp]: data});
  }

  async handleExport () {
    let exportData = [];
    let fields = [];
    let data = (await this.props.api.get('capabilities/export')).data;
    Object.keys(data[0]).forEach(key => {
      if (typeof data[0][key] !== 'object') {
        fields.push(key);
      }
    });
    data.forEach(datum => {
      datum.capabilities.forEach(c => {
        let object = {};
        fields.forEach(f => {
          object[f] = datum[f];
        });
        object = {
          ...object,
          ...datum.addressParts,
          ...c
        }
        exportData.push(object);
      });
    });
    let headers = {
      'companyName': 'Company Name',
      'displayAddress': 'Display Address',
      'approved': 'Approved',
      'denied': 'Denied',
      'deniedComment': 'Denied Comment',
      'dunsNumber': 'DUNS Number',
      'contactName': 'Contact Name',
      'contactEmail': 'Contact Email',
      'contactPhone': 'Contact Phone',
      'website': 'Website',
      'name': 'Capability Name',
      'capacity': 'Capacity',
      'maxCapacity': 'Max Capacity',
      'totalQuantity': 'Total Quantity',
      'unitPrice': 'Unit Price',
      'unitOfMeasure': 'Unit Of Measure',
      'comment': 'Comment',
    };

    let header = [];
    let rows = [];
    for(let i in headers) {
      header.push(headers[i]);
    }
    var csv = header.map(function (header) {
      return '"' + header + '"';
    }).join(',') + '\n';
    exportData.forEach(d => {
      let row = [];
      Object.keys(headers).forEach(key => {
        row.push(d[key]);
      });
      rows.push(row);
    });
    // add the row data after the headers
    csv += rows.map(function (row) {
      return row.map(function (datum) {
        return (typeof datum === 'string') ? '"' + datum + '"' : datum;
      }).join(',');
    }).join('\n');

    // Download the csv
    var filename = `mm-covid-19_${Date.now()}.csv`;
    var blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement('a');
      if (link.download !== undefined) { // feature detection, Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style = 'visibility:hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  async handleRefresh (data) {
    if (!data) {
      data = (await this.props.api.get(this.props.getUrl)).data;
    }
    let currentData = null;
    if (data.length === this.state.data.length) {
      currentData = [...this.state.data];
      for (let d in data) {
        currentData[d] = {...currentData[d], ...data[d]}
      }
    }
    this.setState({
      data: currentData || data
    });
  }

  getNumFilters () {
    let andFilterCount = 0;
    for (let key in this.state.andFilters) {
      if (this.state.andFilters[key] !== "") {
        andFilterCount++;
      }
    }
    return andFilterCount + this.state.orFilters.length + this.state.neFilters.length
  }

  async search () {
    let searchObj = [];
    if (this.state.orFilters.length > 0) {
      let obj = {
        capabilities: {
          $elemMatch: {
            name: {$in: [...this.state.orFilters]}
          }
        }
      };
      if (this.state.neFilters.length > 0) {
        this.state.neFilters.forEach(ne => {
          obj.capabilities.$elemMatch[ne] = {$ne: null};
        });
      }
      searchObj.push(obj)
    } else if (this.state.neFilters.length > 0) {
      this.state.neFilters.forEach(f => {
        searchObj.push({['capabilities.'+ f]: {$ne: null}});
      });
    }

    Object.keys(this.state.andFilters).forEach(f => {
      if (this.state.andFilters[f] !== "") {
        searchObj.push({[f]: this.state.andFilters[f]});
      }
    });

    this.setState({
      data: (await this.props.api.post(`capabilities/search${this.state.query ? '?query=' + this.state.query : ''}`, searchObj)).data
    });
    this.handleClose('showFilter');
  }

  componentWillReceiveProps (props) {
    if (props.data) {
      this.handleRefresh(props.data);
    }
  }

  async componentDidMount() {
    if (!this.props.data) {
      this.handleRefresh();
    }
  }

  render () {
    return (
      <div>
        { (this.props.showFilters || this.props.showExport) &&
          <div style={{margin: "20px", marginTop: "0", display: "flex"}}>
            {this.props.showFilters && 
              <div style={{display: 'inline', paddingRight: '20px'}}>
                <Button
                  variant="primary"
                  onClick={() => {this.handleShow(null, null, 'showFilter')}}>
                    Filter <Badge variant="light">{this.getNumFilters()}</Badge>
                </Button>
                <Modal show={this.state.showFilter} onHide={() => {this.handleClose('showFilter')}}>
                  <Modal.Header closeButton>
                    <Modal.Title>Select Filters Below</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{maxHeight: "70vh", overflow: "auto"}}>
                  <Form.Group controlId="classificationFilter">
                      <Form.Label>Company Classification</Form.Label>
                      <Form.Control as="select"
                        value={this.state.andFilters.companyClassification}
                        name="companyClassification"
                        onChange={this.updateAndFilters}>
                        <option></option>
                        <option key="manufacturer">Manufacturer</option>
                        <option key="Distributor">Distributor</option>
                        <option key="Other">Other</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="stateFilter">
                      <Form.Label>State</Form.Label>
                      <Form.Control as="select"
                        value={this.state.andFilters['addressParts.state_abbreviation']}
                        name='addressParts.state_abbreviation'
                        onChange={this.updateAndFilters}>
                        <option></option>
                        {states.map(state => {
                          return (<option key={state}>{state}</option>)
                        })}
                      </Form.Control>
                    </Form.Group>
                    <div>
                      <h4>Require:</h4>
                      <Form.Row>
                        {
                          [
                            {value: 'capacity', display: 'Capacity'},
                            {value: 'maxCapacity', display: 'Max Capacity'},
                            {value: 'totalQuantity', display: 'Quantity'}
                          ].map((item, i) => {
                            return (
                              <Form.Group style={{padding: '0 20px'}} key={'key' + i} controlId={'checkbox-' + i}>
                                <Form.Check
                                  type="checkbox"
                                  checked={this.state.neFilters.includes(item.value)}
                                  label={item.display}
                                  onChange={(e) => this.updateFilters(item.value, 'neFilters')}/>
                              </Form.Group>
                            )
                          })
                        }
                      </Form.Row>
                    </div>
                    <CategoryList
                      capabilities={[]}
                      updateCapabilities={(e, item) => this.updateFilters(item, 'orFilters')}
                      hideAdditionalInfo={true}
                      isChecked={this.isChecked}
                      api={this.props.api} />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="danger" onClick={this.clearSearch}>Clear Search</Button>
                    <Button variant="primary" onClick={this.search}>Search</Button>
                  </Modal.Footer>
                </Modal>
              </div>
            }
            {this.props.showSearch &&
              <Form onSubmit={e => {e.preventDefault(); this.search()}}>
                <Form.Group controlId="search">
                  <InputGroup>
                  <OverlayTrigger overlay={<Tooltip id="search-tooltip">Use this to search against supplier capabilities and capability notes.</Tooltip>}>
                      <div className="clear-input">
                        <Form.Control type="text" placeholder="Search" name="search" value={this.state.query} onChange={e => this.setState({query: e.target.value})} />
                        {this.state.query !== '' &&
                          <span onClick={e => this.setState({query: ''}, () => this.search())}><FontAwesomeIcon icon={['far', 'times']} /></span>
                        }
                      </div>
                    </OverlayTrigger>
                    <Button type="submit" as={InputGroup.Append}><FontAwesomeIcon icon={['far', 'search']} /></Button>
                  </InputGroup>
                </Form.Group>
              </Form>
            }
            <div style={{flex: 1}} />
            {this.props.showExport &&
              <div style={{float: "right", display: 'inline'}}>
                <Button onClick={() => this.handleExport()}><FontAwesomeIcon icon={['fas', 'file-download']} /></Button>
              </div>
            }
          </div>
        }
        <Modal show={this.state.show} onHide={() => this.handleClose('show', 'currentComment')}>
          <Modal.Header closeButton>
            <Modal.Title>Comment</Modal.Title>
          </Modal.Header>
          <Modal.Body><pre style={{whiteSpace: 'pre-wrap'}}>{this.state.currentComment}</pre></Modal.Body>
        </Modal>
        { this.props.showAdmin &&
          <div>
            <Modal show={this.state.showDenial} onHide={() => {this.handleClose('currentCapability', 'showDenial')}}>
              <Modal.Header closeButton>
                <Modal.Title>Enter a reason for denial</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <InputGroup className="mb-3">
                  <FormControl placeholder="Enter reason here..." name="deniedComment" onChange={this.handleChangeComment} />
                </InputGroup>
                <Button variant="primary" onClick={() => {this.handleDenial(this.state.currentCapability)}}>
                  Submit Denial
                </Button>
              </Modal.Body>
            </Modal>
            <Modal show={this.state.showEdit} onHide={() => {this.handleClose('currentCapability', 'showEdit')}} >
              <Modal.Header closeButton>
                <Modal.Title>Edit Company Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                { this.state.currentCapability &&
                  <CompanyForm
                    handleChange={this.handleEdit.bind(this)}
                    handleAddressParts={this.handleAddressParts.bind(this)}
                    handleCapabilityChange={this.handleEditCapability.bind(this)}
                    handleAddRow={this.handleAddRow.bind(this)}
                    handleRemoveRow={this.handleRemoveRow.bind(this)}
                    data={this.state.currentCapability}
                    validated={this.state.validated}
                    api={this.props.api} />
                }
                <div className="button-div">
                  <Button variant="primary" onClick={() => {this.handleSave(this.state.currentCapability)}}>
                    Save
                  </Button>
                  <Button variant="primary" onClick={() => {this.handleSave(this.state.currentCapability, true)}}>
                    Save & approve
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        }
        <Table responsive>
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Address</th>
              <th>Capabilities</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.data.map((datum, i) => {
                return (
                  <React.Fragment key={`fragment-${i}`}>
                    <tr className="clickable-row" style={{position: 'relative'}} key={`searchresult-${i}`} onClick={e => this.handleExtra('data', i)}>
                      <td>{datum.companyName}</td>
                      <td>{datum.displayAddress}</td>
                      <td>{this.displayCapabilities(datum.capabilities)}</td>
                    </tr>
                    <tr className="hideable-row" style={datum.showExtra ? {} : {display: 'none'}}>
                      <td colSpan="12">
                      <Card>
                        <Card.Body>
                          <Card.Title>
                            Extra information
                          </Card.Title>
                          <Card>
                            <Card.Body>
                              <Container>
                                <Row>
                                  <Col>
                                    <Card.Title>{datum.contactName}</Card.Title>
                                    <Card.Text><FontAwesomeIcon style={{color: '#007dbc'}} icon={['fas', 'envelope']} /> {datum.contactEmail}</Card.Text>
                                    <Card.Text><FontAwesomeIcon style={{color: '#007dbc'}} icon={['fas', 'phone']}
                                      /> {this.formatPhone(datum.contactPhone)} {datum.contactPhoneExtension ? `Ext. ${datum.contactPhoneExtension}` : ''}
                                    </Card.Text>
                                    <Card.Text><FontAwesomeIcon style={{color: '#007dbc'}} icon={['fas', 'globe']} /> {datum.website}</Card.Text>
                                  </Col>
                                  <Col>
                                    <Card.Text className="data-card"><strong>NAICS:</strong> {datum.naics}</Card.Text>
                                    <Card.Text className="data-card"><strong>Certifications:</strong> {datum.certs}</Card.Text>
                                    <Card.Text className="data-card"><strong>SBA Designations:</strong> {datum.sbaDesignation}</Card.Text>
                                  </Col>
                                  <Col>
                                    <Card.Text className="data-card"><strong>DUNS Number:</strong> {datum.dunsNumber}</Card.Text>
                                    <Card.Text className="data-card"><strong>Company Type:</strong> {datum.companyClassification}</Card.Text>
                                  </Col>
                                </Row>
                              </Container>
                            </Card.Body>
                          </Card>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Weekly Capacity</th>
                                <th>Max Capacity</th>
                                <th>Total Quantity</th>
                                <th>Unit Price</th>
                                <th>Measurement</th>
                                <th>Comment</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                datum.capabilities && datum.capabilities.map((capability, c) => {
                                  return (
                                    <tr key={`capability-${i}-${c}`}>
                                      <td>{capability.name}</td>
                                      <td>{capability.capacity}</td>
                                      <td>{capability.maxCapacity}</td>
                                      <td>{capability.totalQuantity}</td>
                                      <td>{capability.unitPrice}</td>
                                      <td>{capability.unitOfMeasure}</td>
                                      <td>
                                        <FontAwesomeIcon
                                          className={capability.comment ? '' : 'hidden'}
                                          onClick={e => this.handleShow('currentComment', capability.comment, 'show')}
                                          style={{width: '100%', height: '1.5em', color: '#007dbc'}}
                                          icon={['far', 'comment-alt-lines']} />
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </Table>
                          { this.props.showAdmin && <div className="button-div" style={{textAlign: 'center'}}>
                            <Button variant="danger" onClick={() => {this.handleShow('currentCapability', datum, 'showDenial')}} >
                              Deny
                            </Button>
                            <Button variant="primary" onClick={() => {this.handleApproval(datum)}}>
                              Approve
                            </Button>
                            <Button variant="info" onClick={() => {this.handleShow('currentCapability', datum, 'showEdit')}} >
                              Edit
                            </Button>
                          </div>
                          }
                        </Card.Body>
                      </Card>
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })
            }
          </tbody>
        </Table>
      </div>
    )
  }
}