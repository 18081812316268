import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

export default class CategoryList extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      categories: []
    }

    this.handleAddRow = this.handleAddRow.bind(this);
    this.handleExtra = this.handleExtra.bind(this);
  }

  async handleRefresh () {
    let data = (await this.props.api.get('categories')).data;
    if (this.props.includeOther) {
      data.push({
        name: 'Other',
        items: [
          ""
        ]
      });
    }
    
    this.setState({
      categories: data
    });
  }

  updateCategory (item, index) {
    let categories = [...this.state.categories];
    categories[categories.length - 1].items[index] = item;
    this.setState({categories: [...categories]});
  }

  handleAddRow (c) {
    let categories = [...this.state.categories]
    categories[c].items.push("");
    this.setState({categories: categories})
  }

  handleExtra (i) {
    let categories = [...this.state.categories]
    categories[i].showList = !categories[i].showList;
    this.setState({categories: categories});
  }

  componentDidMount () {
    this.handleRefresh();
  }

  render () {
    const getForm = (item, i) => {
      let index = this.props.capabilities.findIndex(capability => capability.name === item);
      if (index >= 0) {
        return (
          <Card>
            <Card.Body>
              <Form.Row>
              <Form.Group as={Col} controlId={i + 'capacity'}>
                  <Form.Label>Weekly Capacity</Form.Label>
                  <Form.Control type="text" name="capacity" onChange={(event) => {this.props.handleCapabilityChange(event, item)}} />
                </Form.Group>
                <Form.Group as={Col} controlId={i + 'quantity'}>
                  <Form.Label>Maximum Possible Capacity</Form.Label>
                  <Form.Control type="text" name="maxCapacity" onChange={(event) => {this.props.handleCapabilityChange(event, item)}} />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId={i + 'unitPrice'}>
                  <Form.Label>Total Quantity</Form.Label>
                  <Form.Control type="text" name="totalQuantity" onChange={(event) => {this.props.handleCapabilityChange(event, item)}} />
                </Form.Group>
                <Form.Group as={Col} controlId={i + 'unitPrice'}>
                  <Form.Label>Unit Price</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control type="text" name="unitPrice" onChange={(event) => {this.props.handleCapabilityChange(event, item)}} />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} controlId={i + 'unitOfMeasure'}>
                  <Form.Label>Unit of Measure</Form.Label>
                  <Form.Control as="select" name="unitOfMeasure" onChange={(event) => {this.props.handleCapabilityChange(event, item)}}>
                    <option></option>
                    <option key="each">EA/Bottle</option>
                    <option key="box">Box</option>
                    <option key="case">Case</option>
                    <option key="drum">Drum</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId={i + 'unitPrice'}>
                  <Form.Label>Comment</Form.Label>
                  <Form.Control as="textarea" rows="4" name="comment" onChange={(event) => {this.props.handleCapabilityChange(event, item)}} />
                </Form.Group>
              </Form.Row>
            </Card.Body>
          </Card>
        )
      }
    }
    return (
      <div>
        {this.state.categories.map((category, c) => {
          return (
            <Card key={'category-' + c}>
              <Card.Body>
                <div style={category.showList ? {marginBottom: '20px'}: {}} className="clickable-header no-highlight" onClick={() => {this.handleExtra(c)}}>
                  <h4 >
                    {category.name}
                    <FontAwesomeIcon style={{float: 'right', color: '#007dbc'}} icon={category.showList ? 'chevron-down' : 'chevron-right'} />
                  </h4>
                  {!this.props.hideAdditionalInfo && <span>{category.additionalInformation}</span>}
                </div>
                
                <div className="item-list" style={category.showList ? {height: '100%'} : {height: '0px'}}>
                  {category.name !== 'Other' ? category.items.map((item, i)=> {
                      return (
                        <Form.Group key={'key' + c + i} controlId={'checkbox-' + c + i}>
                          <Form.Check type="checkbox" checked={this.props.isChecked && this.props.isChecked(item)} label={item} onChange={(e) => this.props.updateCapabilities(e, item)}/>
                            {getForm(item, i)}
                        </Form.Group>
                      )
                    }) : category.items.map((item, i)=> {
                      return (
                        <div key={'key' + c + i}>
                          <Form.Group controlId={'checkbox-' + c + i}>
                            <Form.Label>Item Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter a descriptive name..." onChange={(e) => {
                                this.props.updateCapabilities(e, e.target.value, i);
                                this.updateCategory(e.target.value, i);
                              }} />
                            {getForm(item, i)}
                          </Form.Group>
                          {category.items.length - 1 === i &&
                            <Button onClick={() => {this.handleAddRow(c)}}><FontAwesomeIcon icon={['far', 'plus-square']} /> Add Item</Button>
                          }
                        </div>
                      )
                    })
                  }
                </div>
              </Card.Body>
            </Card>
          )
      })}
      </div>
    )
  }
}