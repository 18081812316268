import React from 'react';
import Script from 'react-load-script';
import Form from 'react-bootstrap/Form';

export default function GoogleAddress({name, label, value, handleChange, handleAddressParts}) {
  let autocomplete = {};
  const handleScriptLoad = () => {
    // Declare Options For Autocomplete
    const options = {
      types: [
      ],
    };

    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options,
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    autocomplete.setFields(['address_components', 'formatted_address']);

    // Fire Event when a suggested name is selected
    autocomplete.addListener('place_changed', handlePlaceSelect);
  }

   const handlePlaceSelect = () => {
    let types = {
      'street_number': (parts, address) => {parts.street_address = address.long_name},
      'route': (parts, address) => {parts.route = address.long_name},
      'locality': (parts, address) => {parts.locality = address.long_name},
      'postal_code': (parts, address) => {parts.zip = address.long_name},
      'administrative_area_level_1': (parts, address) => {
        parts.state_long = address.long_name;
        parts.state_abbreviation = address.short_name;
      },
      'country': (parts, address) => {parts.country = address.long_name}
    };
    // Extract City From Address Object
    const addressObject = autocomplete.getPlace();
    const address = addressObject.address_components;

    // Check if address is valid
    if (address) {
      let addressParts = {};
      address.forEach(address => {
        address.types.forEach(type => {
          if (types[type]) {
            types[type](addressParts, address);
          }
        })
      });
      handleAddressParts(addressObject.formatted_address, addressParts);
    }
  }
  return (
    <div style={{width: '100%'}}>
      <Form.Group controlId="autocomplete">
        <Form.Label>{label}</Form.Label>
        <Form.Control placeholder="1234 Main St" required name={name} value={value} onChange={handleChange} />
        <Form.Control.Feedback type="invalid">
            Please provide your street address.
        </Form.Control.Feedback>
      </Form.Group>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_PLACES_KEY}&libraries=places`}
        onLoad={handleScriptLoad}
      />
    </div>
  )
}