import React from 'react';
import '../App.scss';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';


export default function Home (props) {
  return (
    <header className="base-page">
      <div className="home-card">
        <Link to="/capability">
          <Card>
            <Card.Body>
              <FontAwesomeIcon className="home-icon" icon={['far', 'edit']} />
              <Card.Title>Submit capabilities</Card.Title>
            </Card.Body>
          </Card>
        </Link>
        <Link to="/find-suppliers">
          <Card>
            <Card.Body>
              <FontAwesomeIcon className="home-icon" icon={['far', 'parachute-box']} />
              <Card.Title>Find a supplier</Card.Title>
            </Card.Body>
          </Card>
        </Link>
        <strong style={{fontStyle: 'italic', cursor: 'auto'}}>
          This site is powered by the Manufacturers Marketplace.
            See how the Manufacturers Marketplace can help you keep America up and running.
            Visit <a target="_blank" rel="noopener noreferrer" href="https://manufacturersmarketplace.us/">https://manufacturersmarketplace.us/</a>
        </strong>
        <strong style={{
          fontStyle: 'italic',
          cursor: 'auto',
          fontSize: '1.2rem',
          display: 'block',
          padding: '20px 0'
        }}>
          <a target="_blank" rel="noopener noreferrer" href="https://i5services.com/">i5 Services</a> is donating its services to help United States
          manufacturing during the COVID-19 pandemic. As the software developer behind the Manufacturers
          Marketplace and with the support from the National Association of Manufacturers and the State
          Affiliates, i5 is proud to help make a difference.
        </strong>
      </div>
    </header>
  );
};