import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Reaptcha from 'reaptcha';
import GoogleAddress from './googleAddress';
import CategoryList from './category.list';
import InputMask from 'react-input-mask';

class CapabilityForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      formData: {
        companyName: '',
        companyClassification: 'Manufacturer',
        displayAddress: '',
        website: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        capabilities: [],
        allowDataTransmission: false
      },
      reCaptchaEl: null,
      submitted: false,
      validated: false,
      checkbox: 'off'
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCapabilityChange = this.handleCapabilityChange.bind(this);
    this.onVerify = this.onVerify.bind(this);
    this.handleAddRow = this.handleAddRow.bind(this);
  }

  handleSubmit (event) {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      validated: true
    });
    if (form.checkValidity() === false) {
      return;
    }

    let formData = {...this.state.formData}

    formData.contactPhone = formData.contactPhone.replace(/\D/g,'');

    this.props.api.post('capabilities', formData)
      .then(res => {
        this.setState({submitted: true})
      });
  };

  handleAddressParts (formattedAddress, parts) {
    this.setState({
      formData: {
        ...this.state.formData,
        displayAddress: formattedAddress, addressParts: parts}
    });
  }

  handleCapabilityChange (event, item) {
    let formData = {...this.state.formData};
    let fieldName = event.target.name;
    let value = event.target.value;
    let index = formData.capabilities.findIndex(capability => capability.name === item);
    if (index >= 0) {
      formData.capabilities[index][fieldName] = value;
    }
    this.setState({formData: {...formData}});
  }

  handleChange(event) {
    let fieldName = event.target.name;
    let value = event.target.value;
    this.setState({formData: {...this.state.formData, [fieldName]: value}});
  }

  handleExtra (i) {
    let categories = [...this.state.categories]
    categories[i].showList = !categories[i].showList;
    this.setState({categories: categories});
  }

  handleAddRow (c) {
    let categories = [...this.state.categories]
    categories[c].items.push("");
    this.setState({categories: categories})
  }

  handleTerms (e) {
    let formData = {...this.state.formData};
    formData.allowDataTransmission = !formData.allowDataTransmission;
    document.getElementById('terms-checkbox').checked = formData.allowDataTransmission;
    this.setState({formData: formData});
  }

  updateCapabilites (event, item, freeForm) {
    let newCapabilities = JSON.parse(JSON.stringify(this.state.formData.capabilities));
    if (freeForm === undefined) {
      let index = newCapabilities.findIndex(capability => capability.name === item);
      if (index >= 0) {
          newCapabilities.splice(index, 1);
      } else {
        newCapabilities.push({
          name: item,
          capacity: null,
          quantity: null,
          totalQuantity: null,
          unitPrice: null,
          unitOfMeasure: null
        });
      }
    } else {
      let index = newCapabilities.findIndex(capability => capability.freeFormID === freeForm);

      if (index >= 0) {
        newCapabilities[index].name = item;
      } else {
        newCapabilities.push({
          name: item,
          capacity: null,
          quantity: null,
          totalQuantity: null,
          unitPrice: null,
          unitOfMeasure: null,
          freeFormID: freeForm
        });
      }
    }

    this.setState({formData: {...this.state.formData, 'capabilities': newCapabilities}});
  }

  onVerify (recaptchaToken) {
    document.getElementById("capability-form").dispatchEvent(new Event('submit', { cancelable: true }))
  }

  render () {
    if (this.state.submitted === true) {
      return <Redirect to='/thank-you' />
    }

    return (
      <div className="capability-form">
        <p>This form is intended for manufacturers that can provide crucial supplies to help with the COVID-19 pandemic.</p>
        <p>If you are searching for these manufacturers either use the search box at the top of the screen, or click <Link to="/search">here</Link> to search.</p>
        <Form noValidate validated={this.state.validated} id="capability-form" onSubmit={this.handleSubmit.bind(this)}>
          <Form.Row>
            <Form.Group as={Col} controlId="companyName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control type="text" placeholder="Company Name" name='companyName' onChange={this.handleChange} required />
              <Form.Control.Feedback type="invalid">
                Please provide your company name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId='companyClassification'>
              <Form.Label>I am a</Form.Label>
              <Form.Control as="select" name="companyClassification" onChange={this.handleChange} >
                <option key="manufacturer">Manufacturer</option>
                <option key="Distributor">Distributor</option>
                <option key="Other">Other</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <GoogleAddress
            handleChange={this.handleChange}
            name="displayAddress"
            label="Company Address"
            handleAddressParts={this.handleAddressParts.bind(this)} />

          <Form.Row>
            <Form.Group as={Col} controlId="website">
              <Form.Label>Company Website</Form.Label>
              <Form.Control type="text" placeholder="www.example.com" name='website' onChange={this.handleChange} />
            </Form.Group>
            <Form.Group as={Col} controlId="duns" >
              <Form.Label>DUNS Number</Form.Label>
              <InputMask mask="999999999" maskChar="#" alwaysShowMask={true} name='dunsNumber' onChange={this.handleChange} >
                {(inputProps) => <Form.Control {...inputProps} />}
              </InputMask>
            </Form.Group>
          </Form.Row>
  
          <Form.Row>
            <Form.Group as={Col} controlId="contactName">
              <Form.Label>Contact Name</Form.Label>
              <Form.Control type="text" placeholder="First Last" name='contactName' onChange={this.handleChange} required />
              <Form.Control.Feedback type="invalid">
                Please provide your point of contact's name.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
  
          <Form.Row>
            <Form.Group as={Col} controlId="contactPhone">
              <Form.Label>Contact Phone</Form.Label>
              <InputMask mask="(999) 999-9999" maskChar=" " name='contactPhone' onChange={this.handleChange} required  >
                {(inputProps) => <Form.Control {...inputProps} type="text" />}
              </InputMask>
              <Form.Control.Feedback type="invalid">
                Please provide your point of contact's phone number.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="contactPhoneExtension">
              <Form.Label>Phone Extension</Form.Label>
              <InputMask mask="999999" maskChar="" name='contactPhoneExtension' onChange={this.handleChange} >
                {(inputProps) => <Form.Control {...inputProps} type="text" />}
              </InputMask>
              <Form.Control.Feedback type="invalid">
                Please provide your point of contact's phone number.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="contactEmail">
              <Form.Label>Contact Email</Form.Label>
              <Form.Control type="email" placeholder="contact@example.com" name='contactEmail' onChange={this.handleChange} required />
              <Form.Control.Feedback type="invalid">
                Please provide your point of contact's email address.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="contactPhone">
              <Form.Label>Certifications</Form.Label>
              <Form.Control type="text" placeholder="ISO 9001, ISO 13485, etc." name='certs' onChange={this.handleChange} />
            </Form.Group>

            <Form.Group as={Col} controlId="contactEmail">
              <Form.Label>NAICS Codes</Form.Label>
              <Form.Control type="text" placeholder="339112" name='naics' onChange={this.handleChange} />
            </Form.Group>

            <Form.Group as={Col} controlId="contactEmail">
              <Form.Label>SBA Designations</Form.Label>
              <Form.Control type="text" placeholder="Woman-Owned, HUBZone, 8(a), etc." name='sbaDesignation' onChange={this.handleChange} />
            </Form.Group>
          </Form.Row>
        </Form>
        <div style={{marginBottom: '10px'}}>
          <CategoryList
            capabilities={this.state.formData.capabilities}
            updateCapabilities={this.updateCapabilites.bind(this)}
            handleCapabilityChange={this.handleCapabilityChange}
            includeOther={true}
            api={this.props.api} />
        </div>
          
          <div style={{textAlign: 'center'}}>
            <div className="terms-flex" onClick={() => this.handleTerms()}>
              <Form.Group controlId='terms-checkbox'>
                <Form.Check type="checkbox" onChange={(e) => this.handleTerms(e)}/>
              </Form.Group>
              <p>(Optional) I agree to have my information included (at no cost)
                in the Manufacturers Marketplace.
                See <a target="_blank" rel="noopener noreferrer"
                  href="https://manufacturersmarketplace.us/terms-of-service/"
                  onClick={(e) => e.stopPropagation()}>terms</a> and <a target="_blank" rel="noopener noreferrer"
                  href="https://manufacturersmarketplace.us/privacy-policy/"
                  onClick={(e) => e.stopPropagation()}>privacy policy</a> for more information.</p>
            </div>
            
            <Button variant="primary" onClick={() => {
              this.reCaptchaEl.execute();
            }}>
              Submit
            </Button>
          </div>
          <Reaptcha
            ref={e => (this.reCaptchaEl = e)}
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            onVerify={this.onVerify}
            size="invisible"
          />
      </div>
    );
  }
}

export default CapabilityForm
