import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight, faChevronDown, faEnvelope, faPhone, faUser, faKey, faGlobe, faTrash, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import { faCommentAltLines, faEdit, faParachuteBox, faPlusSquare, faMinusSquare, faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';

library.add(
  faChevronRight,
  faChevronDown,
  faCheckCircle,
  faEnvelope,
  faPhone,
  faCommentAltLines,
  faUser,
  faKey,
  faEdit,
  faSearch,
  faTimes,
  faParachuteBox, 
  faPlusSquare,
  faMinusSquare,
  faGlobe,
  faTrash,
  faFileDownload);

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
