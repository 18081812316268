import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import * as components from './components/index';
import PrivateRoute from './components/privateRoute';
import axios from 'axios';

export default class App extends React.Component {
  constructor () {
    super();

    this.state = {
      loggedInStatus: null,
      user: {},
      permissions: [],
      superUser: false,
      api: {
        post: (url, data) => {
          return axios.post(this.buildUrl(url), data, this.getConfig());
        },
        put: (url, data) => {
          return axios.put(this.buildUrl(url), data, this.getConfig());
        },
        get: (url) => {
          return axios.get(this.buildUrl(url), this.getConfig());
        },
        login: (credentials) => {
          return axios.get(this.buildUrl('login'), {
            auth: {
              username: credentials.username,
              password: credentials.password
            }
          });
        },
        logout: () => {
          sessionStorage.removeItem('token');
          return axios.get(this.buildUrl('logout'), this.getConfig());
        }
      }
    }
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.getConfig = this.getConfig.bind(this);
    this.handleRefreshToken = this.handleRefreshToken.bind(this);
  }

  buildUrl (url) {
    return `${process.env.REACT_APP_API_URL}\\${url}`
  }

  getConfig () {
    return {
      headers: {
        Authorization: `Bearer ${this.state.token}`
      }
    }
  }

  checkToken (t) {
    return axios.get(this.buildUrl('check-token'), this.getConfig())
  }

  checkPermissions (permission) {
    if (this.state.superUser) {
      return true;
    }
    return this.state.permissions && this.state.permissions.includes(permission);
  }

  handleAuthentication (userData) {
    sessionStorage.setItem('token', userData.data.token);
    this.setState({
      loggedInStatus: 'LOGGED_IN',
      user: userData.data.username,
      permissions: userData.data.permissions,
      token: userData.data.token,
      superUser: userData.data.superUser
    })
  }

  handleLogout () {
    this.setState({
      loggedInStatus: 'NOT_LOGGED_IN',
      user: null,
      token: null,
      permissions: null,
      superUser: null
    })
  }

  handleRefreshToken (isAuto) {
    console.info('Attempting to refresh...');
    this.state.api.get('refresh-token')
      .then((res) => {
        sessionStorage.setItem('token', res.data.token);
        this.setState({
          token: res.data.token
        });
        setTimeout(() => {
          this.handleRefreshToken();
        }, 240000);
      });
  }

  autoLogin () {
    let token = sessionStorage.getItem('token');
    if (token) {
      this.setState({
        token: token
      }, () => {
        this.checkToken()
          .then((res) => {
            this.handleRefreshToken();
            this.setState({
              loggedInStatus: 'LOGGED_IN',
              user: res.data.username,
              permissions: res.data.permissions,
              superUser: res.data.superUser
            })
          })
          .catch(err => {
            this.setState({
              loggedInStatus: 'NOT_LOGGED_IN'
            })
          });
      });
    } else {
      this.setState({
        loggedInStatus: 'NOT_LOGGED_IN'
      })
    }
  }

  componentDidMount () {
    this.autoLogin();
  }

  render () {
    return (
      <div>
        <components.Navbar
          loggedInStatus={this.state.loggedInStatus}
          handleAuthentication={this.handleAuthentication}
          api={this.state.api}
          handleLogout={this.handleLogout}
          checkPermissions={this.checkPermissions.bind(this)}
        ></components.Navbar>
        <main className="main-container">
          <Switch>
            <Route path="/" exact component={components.Home} />
            <Route path="/capability" exact render={(props) => (
              <components.CapabilityForm {...props} api={this.state.api} />
            )} />
            <Route path="/find-suppliers" exact render={(props) => (
              <components.List {...props} api={this.state.api} extra={{
                getUrl: 'capabilities',
                showFilters: true,
                showSearch: true,
                showExport: this.checkPermissions('EXPORT_CAPABILITIES'),
                showAdmin: this.checkPermissions('UPDATE_CAPABILITIES')
              }} />
            )} />
            <Route path="/thank-you" exact component={components.Thanks} />
            <PrivateRoute
              path="/approve-submissions"
              component={components.List}
              loggedInStatus={this.state.loggedInStatus}
              checkPermission={this.checkPermissions.bind(this)}
              permission='UPDATE_CAPABILITIES'
              extraProps={{
                getUrl: 'admin/capabilities',
                showFilters: false,
                showAdmin: true,
                showExport: this.checkPermissions('EXPORT_CAPABILITIES'),
              }}
              api={this.state.api} />
            <PrivateRoute
              path="/lookup-management"
              component={components.Management}
              loggedInStatus={this.state.loggedInStatus}
              checkPermission={this.checkPermissions.bind(this)}
              permission='UPDATE_CATEGORIES'
              extraProps={{
                apiUrl: 'categories',
                blank: {
                  name: '',
                  additionalInformation: '',
                  items: []
                },
                titleProp: 'name',
                descriptionProp: null,
                formComponent: components.CategoryForm
              }}
              api={this.state.api} />
            <PrivateRoute
              path="/user-management"
              component={components.Management}
              loggedInStatus={this.state.loggedInStatus}
              checkPermission={this.checkPermissions.bind(this)}
              permission='UPDATE_USERS'
              extraProps={{
                apiUrl: 'users',
                blank: {
                  username: '',
                  password: '',
                  confirmPassword: '',
                  role: 'priviledged',
                  name: '',
                  email: ''
                },
                titleProp: 'username',
                descriptionProp: 'name',
                formComponent: components.UserForm
              }}
              api={this.state.api} />
            <PrivateRoute
              path="/data-loader"
              component={components.DataLoader}
              loggedInStatus={this.state.loggedInStatus}
              checkPermission={this.checkPermissions.bind(this)}
              permission='LOAD_CAPABILITIES'
              api={this.state.api} />
            <Redirect to="/" />
          </Switch>
        </main>
      </div>
    );
  }
}
