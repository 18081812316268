import React from 'react';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class DataList extends React.Component {
  render () {
    return (
      <div className="management-list">
        <Card onClick={() => {this.props.handleNew()}}>
          <Card.Body>
            <Card.Title><FontAwesomeIcon icon={['far', 'plus-square']} /> Add New</Card.Title>
            <Card.Text></Card.Text>
          </Card.Body>
        </Card>
        {
          this.props.data.map(datum => {
            return (
              <Card key={`data-item-${datum._id}`} onClick={() => {this.props.handleClick(datum)}}>
                <Card.Body>
                  <Card.Title>{datum[this.props.titleProp]}</Card.Title>
                  <Card.Text>{datum[this.props.descriptionProp]}</Card.Text>
                </Card.Body>
              </Card>
            )
          })
        }
      </div>
    )
  }
}