import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import GoogleAddress from './googleAddress';
import InputMask from 'react-input-mask';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class CompanyForm extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      categories: [],
      nameType: {}
    }
    this.getCategories = this.getCategories.bind(this);
  }

  async getCategories () {
    console.log(this.state.categories);
    if (this.state.categories.length === 0) {
      this.setState({
        categories: (await this.props.api.get('categories')).data
      });
    }
  }

  handleType (type, i) {
    let nameType = {...this.state.nameType}
    nameType[i] = type;
    this.setState({
      nameType: nameType
    });
  }

  render () {
    return (
      <div>
        <Form noValidate validated={this.props.validated} id="capability-form" onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} controlId="companyName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control type="text" placeholder="Company Name" name='companyName' value={this.props.data.companyName || ''} onChange={this.props.handleChange} required />
              <Form.Control.Feedback type="invalid">
                Please provide your company name.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <GoogleAddress
              handleChange={this.props.handleChange}
              value={this.props.data.displayAddress}
              name="displayAddress"
              label="Company Address"
              handleAddressParts={this.props.handleAddressParts} />
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="website">
              <Form.Label>Company Website</Form.Label>
              <Form.Control type="text" placeholder="www.example.com" name='website' value={this.props.data.website || ''} onChange={this.props.handleChange} />
            </Form.Group>
            <Form.Group as={Col} controlId="duns" >
              <Form.Label>DUNS Number</Form.Label>
              <InputMask mask="999999999" maskChar="#" alwaysShowMask={true} name='dunsNumber'  value={this.props.data.dunsNumber || ''} onChange={this.props.handleChange} >
                {(inputProps) => <Form.Control {...inputProps} />}
              </InputMask>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="contactName">
              <Form.Label>Contact Name</Form.Label>
              <Form.Control type="text" placeholder="First Last" name='contactName' value={this.props.data.contactName || ''} onChange={this.props.handleChange} required />
              <Form.Control.Feedback type="invalid">
                Please provide your point of contact's name.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
  
          <Form.Row>
            <Form.Group as={Col} controlId="contactPhone">
              <Form.Label>Contact Phone</Form.Label>
              <InputMask mask="(999) 999-9999" maskChar=" " name='contactPhone' value={this.props.data.contactPhone || ''} onChange={this.props.handleChange} required >
                {(inputProps) => <Form.Control {...inputProps} type="text" />}
              </InputMask>
              <Form.Control.Feedback type="invalid">
                Please provide your point of contact's phone number.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="contactEmail">
              <Form.Label>Contact Email</Form.Label>
              <Form.Control type="email" placeholder="contact@example.com" name='contactEmail' value={this.props.data.contactEmail || ''} onChange={this.props.handleChange} required />
              <Form.Control.Feedback type="invalid">
                Please provide your point of contact's email address.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="contactPhone">
              <Form.Label>Certifications</Form.Label>
              <Form.Control type="text" placeholder="ISO 9001, ISO 13485, etc." name='certs' value={this.props.data.certs || ''} onChange={this.props.handleChange} />
            </Form.Group>

            <Form.Group as={Col} controlId="contactEmail">
              <Form.Label>NAICS Codes</Form.Label>
              <Form.Control type="text" placeholder="339112" name='naics' value={this.props.data.naics || ''} onChange={this.props.handleChange} />
            </Form.Group>

            <Form.Group as={Col} controlId="contactEmail">
              <Form.Label>SBA Designations</Form.Label>
              <Form.Control type="text" placeholder="Woman-Owned, HUBZone, 8(a), etc." name='sbaDesignation' value={this.props.data.sbaDesignation || ''} onChange={this.props.handleChange} />
            </Form.Group>
          </Form.Row>
        </Form>
        {this.props.data.capabilities.length === 0 &&
          <Button onClick={() => {this.getCategories(); this.props.handleAddRow()}}><FontAwesomeIcon icon={['far', 'plus-square']} /> Add Item</Button>
        }
        {
          this.props.data.capabilities.map((item, i) => {
            return (
              <Card key={`capability-${i}`}>
                <Card.Body>
                  {!item.new && <Card.Title>{item.name}</Card.Title>}
                  <Form>
                    {item.new &&
                      <Form.Row>
                      <Form.Group as={Col} controlId={i + 'name'}>
                          <Form.Label>Capability Name</Form.Label>
                          <InputGroup>
                            <DropdownButton
                              as={InputGroup.Prepend}
                              variant="outline-secondary"
                              title={this.state.nameType[i] || 'Select a type'}
                              id="input-group-dropdown-1"
                            >
                              <Dropdown.Item onClick={() => {this.handleType('Lookup', i)}}>Lookup</Dropdown.Item>
                              <Dropdown.Item onClick={() => {this.handleType('Other', i)}}>Other</Dropdown.Item>
                            </DropdownButton>
                            {this.state.nameType[i] === 'Lookup' && 
                              <Form.Control as="select" name="name" value={item.name || ''} onChange={(event) => {this.props.handleCapabilityChange(event, i)}} >
                                <option></option>
                                {this.state.categories.map(c => {
                                  return (
                                    <optgroup key={`group-${c.name}`} label={c.name}>
                                      {c.items.map(item => {
                                        return (
                                          <option key={`option-${item}`}>{item}</option>
                                        )
                                      })}
                                    </optgroup>
                                  )
                                })}
                              </Form.Control>
                            }
                            {this.state.nameType[i] === 'Other' && 
                              <Form.Control type="text" name="name" value={item.name || ''} onChange={(event) => {this.props.handleCapabilityChange(event, i)}} />
                            }
                          </InputGroup>
                        </Form.Group>
                      </Form.Row>
                    }
                    <Form.Row>
                      <Form.Group as={Col} controlId={i + 'capacity'}>
                        <Form.Label>Weekly Capacity</Form.Label>
                        <Form.Control type="text" name="capacity" value={item.capacity || ''} onChange={(event) => {this.props.handleCapabilityChange(event, i)}} />
                      </Form.Group>
                      <Form.Group as={Col} controlId={i + 'quantity'}>
                        <Form.Label>Maximum Possible Capacity</Form.Label>
                        <Form.Control type="text" name="maxCapacity" value={item.maxCapacity || ''} onChange={(event) => {this.props.handleCapabilityChange(event, i)}} />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} controlId={i + 'unitPrice'}>
                        <Form.Label>Total Quantity</Form.Label>
                        <Form.Control type="text" name="totalQuantity" value={item.totalQuantity || ''} onChange={(event) => {this.props.handleCapabilityChange(event, i)}} />
                      </Form.Group>
                      <Form.Group as={Col} controlId={i + 'unitPrice'}>
                        <Form.Label>Unit Price</Form.Label>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control type="text" name="unitPrice" value={item.unitPrice || ''} onChange={(event) => {this.props.handleCapabilityChange(event, i)}} />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} controlId={i + 'unitOfMeasure'}>
                        <Form.Label>Unit of Measure</Form.Label>
                        <Form.Control as="select" name="unitOfMeasure" value={item.unitOfMeasure || ''} onChange={(event) => {this.props.handleCapabilityChange(event, i)}}>
                          <option></option>
                          <option key="each">EA/Bottle</option>
                          <option key="box">Box</option>
                          <option key="case">Case</option>
                          <option key="drum">Drum</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} controlId={i + 'unitPrice'}>
                        <Form.Label>Comment</Form.Label>
                        <Form.Control as="textarea" rows="4" name="comment" value={item.comment || ''} onChange={(event) => {this.props.handleCapabilityChange(event, i)}} />
                      </Form.Group>
                    </Form.Row>
                  </Form>
                  <div>
                    <Button style={{marginRight: "20px"}} variant="danger" onClick={() => {this.props.handleRemoveRow(i)}}><FontAwesomeIcon icon={['far', 'minus-square']} /> Remove Item</Button>
                    {this.props.data.capabilities.length - 1 === i &&
                      <Button onClick={() => {this.getCategories(); this.props.handleAddRow()}}><FontAwesomeIcon icon={['far', 'plus-square']} /> Add Item</Button>
                    }
                  </div>
                </Card.Body>
              </Card>
            )
          })
        }

      </div>
      
    )
  }
}