import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button';
import '../App.scss';
import { LinkContainer } from 'react-router-bootstrap';


export default function Thanks (props) {
  return (
    <header className="base-page">
      <FontAwesomeIcon className="success-icon" icon={['fal', 'check-circle']} />
      <p>Thank you for your submission!</p>
      <p>It will be available on the site within 24 hours.</p>
      <LinkContainer to="/"><Button>Back to home</Button></LinkContainer>
    </header>
  );
};