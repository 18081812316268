import React from 'react';
import CapabilityTable from './capabilityTable';

class List extends React.Component {
  render () {
    return (
      <div style={{padding: "20px 0"}}>
        <CapabilityTable
          {...this.props.extra}
          api={this.props.api} />
      </div>
      
    )
  }
}

export default List