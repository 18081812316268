import React from 'react';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CapabilityTable from './capabilityTable';
import Button from 'react-bootstrap/Button';

export default class DataLoader extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      json: '',
      show: false,
      currentComment: '',
      data: []
    }
    this.textareaRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleChange (event) {
    let value = event.target.value;
    try {
      let data = JSON.parse(value);
      this.setState({
        json: value,
        data: data,
        error: null
      });
    } catch (e) {
      this.setState({
        json: value,
        error: e.message
      });
    }
  }

  handleKeyDown (e) {
    if (e.key === 'Tab') {
      e.preventDefault();
      const value = this.textareaRef.current.value;
      const selectionStart = this.textareaRef.current.selectionStart;
      const selectionEnd = this.textareaRef.current.selectionEnd;
      this.textareaRef.current.value =
        value.substring(0, selectionStart) + '  ' + value.substring(selectionEnd);
      this.textareaRef.current.selectionStart = selectionEnd + 2 - (selectionEnd - selectionStart);
      this.textareaRef.current.selectionEnd = selectionEnd + 2 - (selectionEnd - selectionStart);
    }
  }

  handleLoad () {
    this.props.api.post('admin/capabilities/load', this.state.data)
      .then(res => {
        this.setState({
          success: 'Data Load successful',
          json: '',
          show: false,
          currentComment: '',
          data: []
        });
      })
      .catch(err => {
        this.setState({
          error: err.message
        });
      });
  }

  render () {
    return (
      <div className="loader-div">
        
        <Row style={{height: '90%'}}>
          <Col>
            <Form.Group>
              <Form.Label>Enter JSON here</Form.Label>
              <Form.Control
                ref={this.textareaRef}
                as="textarea"
                rows="30"
                value={this.state.json}
                name="json"
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown} />
            </Form.Group>
          </Col>
          <Col>
            <CapabilityTable data={this.state.data} />
          </Col>
        </Row>
        <Row className="loader-button-row">
          {this.state.error && <Alert variant="danger">{this.state.error}</Alert>}
          {this.state.success && <Alert variant="success">{this.state.success}</Alert>}
          <Button disabled={this.state.error} onClick={this.handleLoad.bind(this)}>Load Data</Button>
        </Row>
      </div>
    );
  } 
}