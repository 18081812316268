import React from 'react';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';

export default class CategoryForm extends React.Component {

  constructor (props) {
    super (props);
    if (props.object) {
      this.state.formData = props.object;
    } else {
      this.state = {
        formData: {
          name: '',
          additionalInformation: '',
          items: [],
          item: ''
        }
      }
    }
    this.state.validate = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange (event) {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    this.setState({formData: {...this.state.formData, [fieldName]: fleldVal}});
  }

  handleAddItem () {
    let items = [...this.state.formData.items];
    items.push(this.state.formData.item);
    this.setState({
      formData: {
        ...this.state.formData,
        items: items,
        item: '',
      }
    });
  }

  handleRemove (index) {
    let items = [...this.state.formData.items];
    items.splice(index, 1);
    this.setState({
      formData: {
        ...this.state.formData,
        items: items
      }
    });
  }

  handleSubmit (event) {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      validated: true
    });
    if (form.checkValidity() === false) {
      return;
    }
    this.props.handleSave(this.state.formData);
  }

  componentWillReceiveProps (props) {
    if (props.currentObj) {
      this.setState({
        formData: props.currentObj,
        validated: false
      });
    }
  }

  render () {
    return (
      <div>
        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
          <Form.Group>
            <Form.Label>Category Name</Form.Label>
            <Form.Control size="lg" type="text" placeholder="Category Name" required value={this.state.formData.name || ''} name="name" onChange={this.handleChange} />
            <Form.Control.Feedback type="invalid">
              Please put a name for the category.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Additional Information</Form.Label>
            <Form.Control
              size="md"
              type="text"
              placeholder="Any additional information goes here..."
              value={this.state.formData.additionalInformation || ''}
              name="additionalInformation"
              onChange={this.handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Add Item</Form.Label>
            <Form.Control size="sm" type="text" placeholder="Item Name" value={this.state.formData.item || ''} name="item" onChange={this.handleChange} />
          </Form.Group>
          <Button onClick={this.handleAddItem} >Add Item </Button>
          <Table responsive>
            <thead>
              <tr>
                <th>Item</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.formData.items.map((item, i) => {
                  return (
                    <tr key={`item-${i}`}>
                      <td>{item}</td>
                      <td>
                        <FontAwesomeIcon
                          onClick={() => {this.handleRemove(i)}}
                          style={{height: '1.5em', color: 'var(--red)', cursor: 'pointer'}}
                          icon="trash" />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
          <Button type="submit">Save</Button>
        </Form>
      </div>
    )
  }
}